import React, { useContext } from "react";
import styles from "../../css/Calculator.module.css";
import { CalculatorContext } from "../../context/CalculatorContext";
import BottomNavCalculatorDisplay from "./BottomNavCalculatorDisplay";

const pi = 3.1416;
const radius = 19;
const coordinates = 25;

function Calculator() {
  const { selected } = useContext(CalculatorContext);

  const alergies = {};
  let fat = 0;
  let prot = 0;
  let cal = 0;
  let carb = 0;
  for (const foodType in selected) {
    if (
      Object.hasOwnProperty.call(selected, foodType) &&
      foodType !== "extraTwoFillets"
    ) {
      const foodTypeIngridients = selected[foodType];
      for (const ingridient in foodTypeIngridients) {
        if (Object.hasOwnProperty.call(foodTypeIngridients, ingridient)) {
          const element = foodTypeIngridients[ingridient];
          fat += element.fat;
          prot += element.protein;
          cal += element.calories;
          carb += element.carbs;
          if (element.soya) alergies.soya = true;
          if (element.sulphite) alergies.sulphite = true;
          if (element.wheat) alergies.wheat = true;
          if (element.milk) alergies.milk = true;
          if (element.mustard) alergies.mustard = true;
          if (element.buckwheat) alergies.buckwheat = true;
          if (element.fish) alergies.fish = true;
          if (element.eggs) alergies.eggs = true;
          if (element.sesame) alergies.sesame = true;
          if (element.all) alergies.all = true;
        }
      }
    }
  }

  if (
    selected["extraTwoFillets"] &&
    selected["extraTwoFillets"]["Extra 1 Fillet"]
  ) {
    const foodTypeIngridients =
      selected["twoFlavorProtein"] ||
      selected["twoFlavorPlantBasedProtein"] ||
      selected["protein"] ||
      selected["plantBasedProtein"] ||
      selected["proteinMini"] ||
      selected["proteinPlantBasedMini"];
    for (const ingridient in foodTypeIngridients) {
      const element = foodTypeIngridients[ingridient];
      if (Object.hasOwnProperty.call(foodTypeIngridients, ingridient)) {
        fat += element.fat / 3;
        prot += element.protein / 3;
        cal += element.calories / 3;
        carb += element.carbs / 3;
      }
    }
    delete Object.keys(selected["extraTwoFillets"]);
  }

  if (
    selected["extraTwoFillets"] &&
    selected["extraTwoFillets"]["Extra 2 Fillets "]
  ) {
    const foodTypeIngridients =
      selected["twoFlavorProtein"] ||
      selected["twoFlavorPlantBasedProtein"] ||
      selected["protein"] ||
      selected["plantBasedProtein"] ||
      selected["proteinMini"] ||
      selected["proteinPlantBasedMini"];
    for (const ingridient in foodTypeIngridients) {
      const element = foodTypeIngridients[ingridient];
      if (Object.hasOwnProperty.call(foodTypeIngridients, ingridient)) {
        fat += element.fat / 2;
        prot += element.protein / 2;
        cal += element.calories / 2;
        carb += element.carbs / 2;
      }
    }
    delete Object.keys(selected["extraTwoFillets"]);
  }

  if (selected["extraTwoFillets"] && selected["extraTwoFillets"]["No Thanks"]) {
    if (Object.keys(selected["extraTwoFillets"]).length < 1) {
      fat += 0;
      prot += 0;
      cal += 0;
      carb += 0;
    }
  }

  if (selected["base"]) {
    let baseCount = Object.keys(selected["base"]).length;
    let totalBaseCalories = 0;

    for (const baseName in selected["base"]) {
      if (Object.hasOwnProperty.call(selected["base"], baseName)) {
        const base = selected["base"][baseName];
        totalBaseCalories += base.calories;
      }
    }

    if (baseCount === 2) {
      cal -= totalBaseCalories;
      cal += totalBaseCalories /= 2;
    }
  }

  if (selected["bowls"] && selected["bowls"]["Mini Bowl"]) {
    const foodTypeIngridients = selected["rougeBowlsDressings"];
    for (const ingredient in foodTypeIngridients) {
      const element = foodTypeIngridients[ingredient];
      if (Object.hasOwnProperty.call(foodTypeIngridients, ingredient)) {
        fat -= element.fat / 2;
        prot -= element.protein / 2;
        cal -= element.calories / 2;
        carb -= element.carbs / 2;
      }
    }
  }

  function isFloat(n) {
    return Number(n) === n && n % 1 !== 0;
  }

  function checkNumber(check, number) {
    if (check) {
      return number.toFixed(1);
    } else {
      return number.toFixed(0);
    }
  }

  const handleValue = (value) => {
    let total = prot + carb + fat;
    if (value === 0) {
      return 0;
    } else {
      return (value / total) * 100;
    }
  };

  return (
    <>
      <div className={styles.container} >
        <div className={styles.calcContainer}>
          <div className={styles.chart}>
            <svg
              className={styles.svg}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 50 50"
              height={50}
            >
              <circle
                r={radius}
                cx={coordinates}
                cy={coordinates}
                stroke="#ffffff"
                transform="rotate(-90) translate(-50)"
                className={styles.circle}
              />
              <circle
                r={radius}
                cx={coordinates}
                cy={coordinates}
                transform="rotate(-90) translate(-50)"
                stroke="#646464"
                className={styles.circle}
                style={{
                  strokeDasharray: `calc(${
                    handleValue(fat) + handleValue(prot)
                  } * (${pi} * ${radius * 2})/100) calc(${pi} * ${radius * 2})`,
                }}
              />
              <circle
                r={radius}
                cx={coordinates}
                cy={coordinates}
                stroke="#ba0020"
                transform="rotate(-90) translate(-50)"
                className={styles.circle}
                style={{
                  strokeDasharray: `calc(${handleValue(prot)} * (${pi} * ${
                    radius * 2
                  })/100) calc(${pi} * ${radius * 2})`,
                }}
              />
            </svg>
            <div className={styles.calories}>
              <p>CALORIES</p>
              <p>{checkNumber(isFloat(cal), cal)}</p>
            </div>
          </div>
          <div className={styles.nutritionDisplay}>
            <div className={styles.calcDisplayFatProtein}>
              <div className={styles.fatProteinBlocs}>
                <p className={styles.carbP}>CARBOHYDRATES</p>
                <p>{checkNumber(isFloat(carb), carb)} G</p>
              </div>
              <div className={styles.fatProteinBlocs}>
                <p className={styles.fatP}>FAT</p>
                <p>{checkNumber(isFloat(fat), fat)} G</p>
              </div>
            </div>
            <div className={styles.calcDisplayCarbs}>
              <p>PROTEIN</p>
              <p>{checkNumber(isFloat(prot), prot)} G</p>
            </div>
          </div>
          <div className={styles.hr} />
          <div className="alleregiesContainer">
            <div className="heading">
              <h3>ALLERGIES</h3>
            </div>
          </div>
          <div className={styles.allergiesContainer}>
            <div className={styles.allergies}>
              {alergies.soya ? (
                <div className={styles.allergyCard}>
                  <img src="/assets/images/soya.png" alt="" />
                </div>
              ) : null}
              {alergies.sulphite ? (
                <div className={styles.allergyCard}>
                  <img src="/assets/images/sulphite.png" alt="" />
                </div>
              ) : null}
              {alergies.wheat ? (
                <div className={styles.allergyCard}>
                  <img src="/assets/images/gluten.png" alt="" />
                </div>
              ) : null}
              {alergies.milk ? (
                <div className={styles.allergyCard}>
                  <img src="/assets/images/milk.png" alt="" />
                </div>
              ) : null}
              {alergies.mustard ? (
                <div className={styles.allergyCard}>
                  <img
                    className={styles.mustard}
                    src="/assets/images/mustard.png"
                    alt=""
                  />
                </div>
              ) : null}
              {alergies.buckwheat ? (
                <div className={styles.allergyCard}>
                  <img
                    className={styles.buckwheat}
                    src="/assets/images/buckwheat.png"
                    alt=""
                  />
                </div>
              ) : null}
              {alergies.fish ? (
                <div className={styles.allergyCard}>
                  <img src="/assets/images/fish.png" alt="" />
                </div>
              ) : null}
              {alergies.eggs ? (
                <div className={styles.allergyCard}>
                  <img src="/assets/images/eggs.png" alt="" />
                </div>
              ) : null}
              {alergies.sesame ? (
                <div className={styles.allergyCard}>
                  <img src="/assets/images/sesame.png" alt="" />
                </div>
              ) : null}
            </div>
            {alergies.all ? (
              <div>
                <img
                  className={styles.allAllergies}
                  src="/assets/images/allergeninhouse-EN.png"
                  alt=""
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <BottomNavCalculatorDisplay
        fat={fat}
        prot={prot}
        cal={cal}
        carb={carb}
        alergies={alergies}
      />
    </>
  );
}

export default Calculator;
