import React, { useContext, useEffect } from "react";
import styles from "../../css/BottomNavCalculatorDisplay.module.css";

import { CalculatorContext } from "../../context/CalculatorContext";
import { IoCloseOutline } from "react-icons/io5";

const pi = 3.1416;
const radius = 19;
const coordinates = 25;

const BottomSheetCalculator = ({ prot, fat, carb, cal, alergies }) => {
  const { handleCalculatorSlider, calculatorSlider } =
    useContext(CalculatorContext);

    useEffect(() => {
      if (calculatorSlider) {
        document.body.classList.add("no-scroll");
      } else {
        document.body.classList.remove("no-scroll");
      }
    }, [calculatorSlider]);

  function checkNumber(check, number) {
    if (check) {
      return number.toFixed(1);
    } else {
      return number.toFixed(0);
    }
  }

  function isFloat(n) {
    return Number(n) === n && n % 1 !== 0;
  }

  const handleValue = (value) => {
    let total = prot + carb + fat;
    if (value === 0) {
      return 0;
    } else {
      return (value / total) * 100;
    }
  };

  return (
      <div
        className={styles.overlay}
        style={{
          height: calculatorSlider ? "90%" : "0%",
          transition: "height 0.3s ease",
        }}
      >
        <div className={styles.buttonClose} onClick={handleCalculatorSlider}>
          <IoCloseOutline size={35} />
        </div>
        <div className={styles.container}>
          <div className={styles.calcContainer}>
            <div className={styles.chart}>
              <svg
                className={styles.svg}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 50 50"
                height={50}
              >
                <circle
                  r={radius}
                  cx={coordinates}
                  cy={coordinates}
                  stroke="#ffffff"
                  transform="rotate(-90) translate(-50)"
                  className={styles.circle}
                />
                <circle
                  r={radius}
                  cx={coordinates}
                  cy={coordinates}
                  transform="rotate(-90) translate(-50)"
                  stroke="#646464"
                  className={styles.circle}
                  style={{
                    strokeDasharray: `calc(${
                      handleValue(fat) + handleValue(prot)
                    } * (${pi} * ${radius * 2})/100) calc(${pi} * ${
                      radius * 2
                    })`,
                  }}
                />
                <circle
                  r={radius}
                  cx={coordinates}
                  cy={coordinates}
                  stroke="#ba0020"
                  transform="rotate(-90) translate(-50)"
                  className={styles.circle}
                  style={{
                    strokeDasharray: `calc(${handleValue(prot)} * (${pi} * ${
                      radius * 2
                    })/100) calc(${pi} * ${radius * 2})`,
                  }}
                />
              </svg>
              <div className={styles.calories}>
                <p>CALORIES</p>
                <p>{checkNumber(isFloat(cal), cal)}</p>
              </div>
            </div>
            <div className={styles.nutritionDisplay}>
              <div className={styles.calcDisplayFatProtein}>
                <div className={styles.fatProteinBlocs}>
                  <p className={styles.carbP}>CARBOHYDRATES</p>
                  <p>{checkNumber(isFloat(carb), carb)} G</p>
                </div>
                <div className={styles.fatProteinBlocs}>
                  <p className={styles.fatP}>FAT</p>
                  <p>{checkNumber(isFloat(fat), fat)} G</p>
                </div>
              </div>
              <div className={styles.calcDisplayCarbs}>
                <p>PROTEIN</p>
                <p>{checkNumber(isFloat(prot), prot)} G</p>
              </div>
            </div>
            <div className={styles.hr} />
            <div className="alleregiesContainer">
              <div className="heading">
                <h3>ALLERGIES</h3>
              </div>
            </div>
            <div className={styles.allergiesContainer}>
              <div className={styles.allergies}>
                {alergies.soya ? (
                  <div className={styles.allergyCard}>
                    <img src="/assets/images/soya.png" alt="" />
                  </div>
                ) : null}
                {alergies.sulphite ? (
                  <div className={styles.allergyCard}>
                    <img src="/assets/images/sulphite.png" alt="" />
                  </div>
                ) : null}
                {alergies.wheat ? (
                  <div className={styles.allergyCard}>
                    <img src="/assets/images/gluten.png" alt="" />
                  </div>
                ) : null}
                {alergies.milk ? (
                  <div className={styles.allergyCard}>
                    <img src="/assets/images/milk.png" alt="" />
                  </div>
                ) : null}
                {alergies.mustard ? (
                  <div className={styles.allergyCard}>
                    <img
                      className={styles.mustard}
                      src="/assets/images/mustard.png"
                      alt=""
                    />
                  </div>
                ) : null}
                {alergies.buckwheat ? (
                  <div className={styles.allergyCard}>
                    <img
                      className={styles.buckwheat}
                      src="/assets/images/buckwheat.png"
                      alt=""
                    />
                  </div>
                ) : null}
                {alergies.fish ? (
                  <div className={styles.allergyCard}>
                    <img src="/assets/images/fish.png" alt="" />
                  </div>
                ) : null}
                {alergies.eggs ? (
                  <div className={styles.allergyCard}>
                    <img src="/assets/images/eggs.png" alt="" />
                  </div>
                ) : null}
                {alergies.sesame ? (
                  <div className={styles.allergyCard}>
                    <img src="/assets/images/sesame.png" alt="" />
                  </div>
                ) : null}
              </div>
              {alergies.all ? (
                <div>
                  <img
                    className={styles.allAllergies}
                    src="/assets/images/allergeninhouse-EN.png"
                    alt=""
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
  );
};

export default BottomSheetCalculator;
